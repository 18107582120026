import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import { appRoutes } from '../Constants'
// import '../Style/style.css'
function AppLayouts() {
    const { team } = useSelector(state => state.team)
    return (
        <>
            <div class="background">
                <div class="brandId">
                    <div class="container">
                        <Link class="navbar-brand" to={appRoutes.home}><img src="./images/logoJpl.png" />
                            <h1>Jain Premier League 5</h1>
                        </Link>
                    </div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-light menuBar">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="collapse navbar-collapse" id="navbarNav">
                                    <ul class="navbar-nav ml-auto">
                                        <li class="nav-item">
                                            <Link class="nav-link font-weight-bold" style={{ fontSize:'16px' }} to={appRoutes.home}>{"Dashboard".toUpperCase()}</Link>
                                        </li>
                                        {
                                            team.map((e) =>
                                                <>
                                                    <li class="nav-item font-weight-bold">
                                                        <Link class="nav-link" style={{ fontSize:'16px' }} to={`${appRoutes.team}/${e.id}`}>{e.team_name.toUpperCase()}</Link>
                                                    </li>
                                                </>
                                            )
                                        }
                                    </ul>
                                    <Link to={appRoutes.player}><div class="btn btn-primary plybtn font-weight-bold" style={{ fontSize:'16px' }} >All Players</div></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Outlet />
                <div class="footer">Managed by Pranvi Sports</div>
            </div>
        </>
    )
}

export default AppLayouts