import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sold } from '../assets';

function Player() {
  const { player } = useSelector(state => state.palyer)
  const [searchTerm, setSearchTerm] = useState('');
  const [playerData, setPlayerData] = useState([]);

  // Update playerData whenever player state changes
  useEffect(() => {
    setPlayerData(player);
  }, [player]);


  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setPlayerData(player);
    } else {
      const filteredData = player.filter(player => player.player_no === parseInt(e.target.value));
      setPlayerData(filteredData);
    }
  };

  console.log("playerData",playerData);
  return (
    <>
      <input
        type="text"
        id="player-no"
        placeholder="Type player number..."
        value={searchTerm}
        onChange={handleInputChange}
        style={{ width: '40%', height: '50px', margin: 20, borderRadius: '10px', border: 0, padding: '5px', fontSize: '15px' }}
      />
      <div className='row'>


        {playerData.map(e => <>
          <div class="image col-md-6 col-lg-4  col-sm-12" >
            <img class="image col-12" src={e.image} />
            {(e.sold != false) ?
              <img class="image col-12" src={sold} style={
                {
                  position: 'absolute',
                  top: '80px',
                  right: '30px',
                  width: '150px', /* Adjust the width and height according to your icon size */
                  height: '150px',
                  objectFit: 'contain',
                }
              } /> : <></>
            }
          </div>
        </>)}
        <div class="image col-md-6 col-lg-4  col-sm-12">
          {/* <img class="image col-12" src={sold} alt="1.jpg" /> */}
        </div>

      </div>
    </>
  )
}

export default Player