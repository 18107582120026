import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchteams } from "./Stores/Slice/teamSlice";
import { fetcchplayer } from "./Stores/Slice/playerSlice";
import Routess from "./Routes/Index";
import Echo from "laravel-echo";
import Pusher from 'pusher-js';

const echo = new Echo({
  broadcaster: 'pusher',
  key: 'e2d2b5753a88649eb589',
  cluster: 'ap2',
  forceTLS: true
});

function App() {
  const fetchData=()=>{
    dispatch(fetchteams())
    dispatch(fetcchplayer())
  }

  useEffect(()=>{
    echo.channel('uiupadetes')
    .listen('Uiupdate', (data) => {
        console.log("uiupdate");
        fetchData()
    });
  },[])
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData()
  },[])
  return (
    <>
      <Routess />
    </>
  );
}

export default App;
