import React from 'react'
import { useSelector } from 'react-redux'
import "../Style/index.css"
import { appRoutes } from '../Constants'
import { Link } from 'react-router-dom';

function LiveAuction() {
  const { team } = useSelector(state => state.team)
  // console.log(team);
  return (
    <>
      <div class="container-fluid" style={{ margin: "40px 0" }}>
        <div class="row">
          <div class="col-md-12 section">
            {team.map(e => <>
              < >
                <Link to={`${appRoutes.team}/${e.id}`} style={{ textDecoration: 'none' }} class="col-lg-3 col-md-4 col-sm-6 col-12 my-4">
                  <div class="box">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '10px', position: 'absolute', marginTop: -30 }} >
                      <img src={e.logo} style={{ height: '100px', width: '100px', borderRadius: '100%', backgroundColor: '#ffffff', boxShadow: '2px 2px 15px 0px #e2e2e2', marginBottom: '20px' }} />
                    </div>
                    <div class="detail">
                      <h5 class="teamN">{e.team_name}</h5>
                      <h6 class="OwnerN"><b>{e.owner_name}</b></h6>
                      <div class="amt">

                      </div>
                      <div class="row" style={{ alignItems: 'center' }} >
                        <div className="col-6">
                          <h5>Balance</h5>
                        </div>
                        <div className="col-6">
                          <h5 className='text-success font-weight-bold' >{e.totale_points}</h5>
                        </div>
                      </div>
                      <div class="row" style={{ alignItems: 'center' }} >
                        <div className="col-6">
                          <h5>Max Bid</h5>
                        </div>
                        <div className="col-6">
                          <h5 className='text-danger font-weight-bold'>{e.max_bid_points}</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div className="col-6">
                          <h5>Player</h5>
                        </div>
                        <div className="col-6">
                          <h5>{e.soldplayer.length}/12</h5>
                        </div>
                      </div>

                    </div>
                  </div>
                </Link>
              </>
            </>)}
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveAuction
