import React from 'react'
import { useSelector } from 'react-redux'
// import '../Style/table.css'
import { useParams } from 'react-router-dom'

function Team() {
  const { team } = useSelector(state => state.team)
  const { id } = useParams('id')
  const teamData = team.filter(e => e.id == id)[0]
  console.log(teamData?.soldplayer);
  return (
    <>
      <div className="col-12 bg-white">
        <h2 className='text-dark my-3 '>{teamData?.team_name.toUpperCase()} PLAYER LIST</h2> 
      </div>
      <div class="table-responsive bg-white my-3">
        <table class="table my-3 b-success" style={{ borderColor:'green' }}>
          <thead>
            <tr>
              <td>#</td>
              <td>ID </td>
              <td>NAME</td>
              <td>MOBILE NO</td>
              <td>SKILL</td>
              <td>BASE POINT</td>
              <td>PURCHASE POINT</td>
            </tr>
          </thead>
          <tbody>
            {teamData?.soldplayer.map((e, key) =>
              <>
                <tr>
                  <td>{++key}</td>
                  <td>{e?.players?.player_no}</td>
                  <td>{e?.players?.name}</td>
                  <td>{e?.players?.mobaile}</td>
                  <td>{e?.players?.skill}</td>
                  <td>{e?.players?.playercategurie?.points}</td>
                  <td>{e?.sold}</td>
                </tr>
              </>)}
          </tbody>
        </table>
      </div>

      {/* </div> */}
    </>
  )
}

export default Team